// $primary: #a51616;
$primary: #0157ae; /* MAIN COLOR */
$secondary: #d42729; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;


@import url("https://fonts.googleapis.com/css?family=Cinzel|Cormorant+Garamond");

h1, h2, h3 {
  font-family: 'Cinzel', serif;
}

p {
  font-family: 'Cormorant Garamond', serif;
  font-size: 15px;
  font-weight: 600;
}

.xe-warning, .xdebug-error, .flash {
  display: none;
}

nav {
  z-index: 1000;
}

.navbar {
  background-color: #fff;
  .navbar-nav > li > a {
    font-size: 16px;
    text-align: center;
    height: 135px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #0157ae;

    &:focus, &:visited {
      outline: 0;
       color: #0157ae;
    }

    &:hover {
      background: transparent;
      color: #d42729;
    }
  }
}

@media (max-width: 767px) {
  .navbar .navbar-nav > li > a {
    height: 30px;
    display: inline-block;
  }
}

.navbar-right {
  margin-top: 0px;
}

.navbar-toggle {
  margin: 30px 15px 8px 0px;
  border: 1px solid transparent;
  border-radius: 4px;
}

@media (max-width: 767px) {
  .navbar-toggle {
    margin-top: 25px;
  }
}

/** LOGIN FORM **/

.modal-dialog {
  max-width: 300px;
  text-align: center;
  margin: 6em auto;
  .close {
    display: none;
  }
  .modal-content {
    color: #0157ae;
    h2 {
      text-align: center;
    }
  }
  input {
    border: none;
    border-bottom: 1px solid #002449;
    text-align: center;
  }
  button {
    background: transparent;
    color: #fafafa;
    display: block;
    border: none;
    padding: 1em 2em;
    margin: 0 auto;
    &:hover {
      background: transparent;
      color: #fff;
      box-shadow: none;
      text-shadow: 0px 0px 3px #333;
      border: none;
    }
  }
  input[type="submit"] {
    text-align: center;
    margin: 0 auto;
    border: 1px solid #0157ae;
    border-radius: 0px;
    background: #0157ae;
    color: #fafafa;
    padding: 0.5em 2em;
    display: block;
    width: 75%;
    &:hover {
      background: #0170e1;
      color: white;
      box-shadow: 0px 0px 3px #4d4d4d;
    }
  }
}

.modal-header, .modal-footer {
  background: #0157ae;
  color: #fafafa;
}

input#username {
  margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
  padding: 50px 0px 20px;
  background: #0157ae;
  color: #fafafa;
  a {
    color: #fafafa;
    &:hover {
      color: white;
    }
  }
}

input {
  &#username, &#password {
    width: 100%;
  }
}

@media (max-width: 1024) {
  input {
    &#username, &#password {
      font-size: 16px;
    }
  }
}

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

.header {
  background-image: url("../img/main_banner.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -webkit-background-size: cover;
}

@media (min-width: 991px) {
  .header {
    background-attachment: unset;
  }
}

@media (max-width: 991px) {
  .header {
    background-attachment: scroll;
    background-attachment: unset;
  }
}

@media (max-width: 767px) {
  .header {
    background-position: 35% 0%;
  }
}

.header-overlay {
  background-color: rgba(31, 31, 31, 0.2);
  padding: 300px 0px;
}

@media (max-width: 767px) {
  .header-overlay {
    padding: 150px 0px;
  }
}

.logo {
  padding-left: 15px;
  padding-bottom: 25px;
  margin-top: 10px;
}

@media (max-width: 767px) {
  .logo {
    padding-bottom: 15px;
    padding-left: 5px;
    width: 100%;
    max-width: 150px;
  }
}

.logo-large {
  display: block;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .logo-large .logo-large {
    width: 100%;
    display: block;
    margin: 0 auto;
  }
}

.padBoth {
  padding-top: 25px;
  padding-bottom: 25px;
}

.padTop {
  padding-top: 50px;
}

.padBot {
  padding-bottom: 50px;
}

.container-fluid.noPad {
  margin-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  margin: 0;
  background: white;
  .row, .col-md-4 {
    margin: 0px;
    padding: 0;
  }
}

.miniform {
  background: -webkit-linear-gradient(top, rgba(212, 39, 41, 0.5) 0%, rgba(1, 87, 174, 0.7) 100%, #000000 100%), url(../img/banner.jpg);
  background: linear-gradient(to bottom, rgba(212, 39, 41, 0.5) 0%, rgba(1, 87, 174, 0.7) 100%, #000000 100%), url(../img/banner.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  font-weight: bold;
  text-align: center;
  h1 {
    position: relative;
    font-size: 3em;
  }
  p {
    font-size: 1.6em;
    line-height: 40px;
    font-weight: 600;
    margin-top: 40px;
  }
}

@media (min-width: 991px) {
  .miniform {
    background-attachment: unset;
  }
}

@media (max-width: 991px) {
  .miniform {
    background-position: 50% 50%;
    background-attachment: unset;
  }
}

@media (max-width: 767px) {
  .miniform {
    padding: 50px;
  }
}

@media (max-width: 767px) {
  .miniform h1 {
    font-size: 2em;
  }
}

@media (max-width: 767px) {
  .miniform p {
    font-size: 1.2em;
  }
}

.btn {
  background-color: #d42729;
  font-size: 15px;
  padding: 1em 2em;
  color: #fafafa;

  &:focus:active {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  &:hover {
    background-color: #b50617;
    color: #fafafa;
  }
}

.pic1 {
  background: url(../img/features_1.jpg) no-repeat;
  background-size: cover;
  height: 300px;
  background-position: 50% 50%;
}

@media (max-width: 991px) {
  .pic1 {
    height: 400px;
  }
}

@media (max-width: 500px) {
  .pic1 {
    background-position: 80% 50%;
  }
}

.pic2 {
  background: url(../img/features_2.jpg) no-repeat;
  background-size: cover;
  height: 300px;
  background-position: 50% 50%;
}

@media (max-width: 991px) {
  .pic2 {
    height: 400px;
  }
}

.pic3 {
  background: url(../img/features_3.jpg) no-repeat;
  background-size: cover;
  height: 300px;
  background-position: 50% 50%;
}

@media (max-width: 991px) {
  .pic3 {
    height: 400px;
  }
}

@media (max-width: 500px) {
  .pic3 {
    background-position: 30% 50%;
  }
}

.overlay {
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;
  img {
    width: 100%;
  }
  h1, p {
    position: absolute;
  }
  h1 {
    top: 40%;
    color: white;
    opacity: 0;
    -webkit-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease;
    z-index: 2;
    width: 100%;
  }
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  &:hover {
    h1, p {
      opacity: 1;
    }
    &:after {
      opacity: 1;
      background: rgba(0, 0, 0, 0.6);
    }
  }
}

@media (max-width: 1024px) {
  .overlay h1 {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .overlay:after {
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
  }
}

.call-to-action {
  background: -webkit-linear-gradient(top, rgba(212, 39, 41, 0.5) 0%, rgba(1, 87, 174, 0.7) 100%, #000000 100%), url(../img/form-bg.jpg);
  background: linear-gradient(to bottom, rgba(212, 39, 41, 0.5) 0%, rgba(1, 87, 174, 0.7) 100%, #000000 100%), url(../img/form-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 75px 0;
  text-align: center;
  background-attachment: fixed;
  h1 {
    position: relative;
    text-align: center;
    color: #fff;
    font-size: 5em;
    font-weight: bold;
  }
  p {
    color: #fff;
    font-size: 1.6em;
    line-height: 40px;
    font-weight: 600;
    text-align: left;
    margin-top: 40px;
    padding-bottom: 25px;
  }
}

@media (max-width: 991px) {
  .call-to-action {
    background-attachment: scroll;
  }
}

@media (max-width: 767px) {
  .call-to-action h1 {
    font-size: 3em;
  }
}

@media (max-width: 767px) {
  .call-to-action p {
    text-align: center;
  }
}